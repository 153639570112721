<template>
    <v-form>
        <action-buttons 
            :waiting-save-and-back="waiting.save.back"
            :waiting-save-and-update="waiting.save.update"
            @back="back"
            @save="save"
        />

        <v-divider class="my-4" />

        <v-alert
          v-if="!_.isNil(summaryError)"
          dense
          type="error"
        >
            {{ summaryError }}
        </v-alert>

        <v-switch
          v-model="form.active"
          color="purple darken-2"
          label="Активность"
        ></v-switch>

        <v-select
            v-model="$v.form.role.$model"
            :readonly="isUpdate"
            :error-messages="getErrors('form.role')"
            required
            :disabled="!!$v.form.role.$model"
            :items="roles"
            label="Роль"
        ></v-select>

        <template v-if="!isRoleStudent">
            <v-text-field 
                v-model="$v.form.login.$model"
                :error-messages="getErrors('form.login')"
                autocomplete="new-email"
                label="Логин" 
                name="login" 
                type="text" 
                placeholder=" "
                color="purple darken-2" 
            ></v-text-field>

            <v-text-field 
                v-model="$v.form.new_password.$model"
                :error-messages="getErrors('form.new_password')"
                autocomplete="new-password"
                label="Пароль" 
                name="new_password" 
                type="password" 
                placeholder=" " 
                color="purple darken-2"
            ></v-text-field>

            <v-text-field 
                v-model="$v.form.confirm_password.$model"
                :error-messages="getErrors('form.confirm_password', {
                    compare: 'Пароль не совпадает с подтверждением'
                })"
                label="Подтверждение пароля" 
                name="confirm_password" 
                type="password" 
                placeholder=" " 
                color="purple darken-2"
            ></v-text-field>
        </template>

        <v-text-field 
            v-model="$v.form.name.$model"
            :error-messages="getErrors('form.name')"
            label="ФИО" 
            name="name" 
            type="text" 
            placeholder=" "
            autofocus="autofocus" 
            color="purple darken-2" 
        ></v-text-field>

        <v-select
            v-if="isRoleStudent"
            v-model="$v.form.gender.$model"
            :items="genders"
            :error-messages="getErrors('form.gender')"
            :menu-props="{ maxHeight: '500' }"
            label="Пол"
            color="purple darken-2"
        ></v-select>
        
        <template v-if="['teacher', 'student'].includes(form.role)">

            <v-divider class="my-4" />

            <v-select
                v-model="$v.form.group_id.$model"
                :items="groups"
                :error-messages="getErrors('form.group_id')"
                :menu-props="{ maxHeight: '500' }"
                label="Классы"
                :multiple="form.role === 'teacher'"
                chips
                hint="Выберите классы"
                persistent-hint
                color="purple darken-2"
                :loading="loadingBars.group"
                :class="{'untouchable': !groups.length}"
            ></v-select>
        </template>

        <div v-if="form.role === 'teacher'">
            <div class="pt-4 caption" :class="{'red--text': $v.form.$dirty && !$v.form.subjects.required }">Предметы</div>
            <div v-if="$v.form.$dirty && !$v.form.subjects.required" class="caption ls-0 red--text">Необходимо выбрать хотя бы 1 предмет.</div>
            <v-progress-linear
                v-if="loadingBars.license"
                indeterminate
                color="purple darken-2"
            ></v-progress-linear>
            <v-data-table
                v-else
                :headers="[
                    { text: 'Предмет', value: 'subject' },
                    ..._.map(_.range(1,12), grade => {
                        return { 
                            text: `${grade} кл.`,
                            value: `grade_${grade}`, 
                            width: 1, 
                            class: 'text-no-wrap', 
                            align: 'center',
                            sortable: false
                        }
                    })
                ]"
                :items="form.subjectsTable"
                :items-per-page="100"
                hide-default-footer
                class="elevation-1"
            >
                <template #[`item.grade_1`]="{ item }">
                    <v-simple-checkbox
                        :indeterminate="!item.grade_1.show"
                        :disabled="!item.grade_1.show"
                        v-model="item.grade_1.value"
                        :color="$const.color.primary"
                        :ripple="false"
                    ></v-simple-checkbox>
                </template>
                <template #[`item.grade_2`]="{ item }">
                    <v-simple-checkbox
                        :indeterminate="!item.grade_2.show"
                        :disabled="!item.grade_2.show"
                        v-model="item.grade_2.value"
                        :color="$const.color.primary"
                        :ripple="false"
                    ></v-simple-checkbox>
                </template>
                <template #[`item.grade_3`]="{ item }">
                    <v-simple-checkbox
                        :indeterminate="!item.grade_3.show"
                        :disabled="!item.grade_3.show"
                        v-model="item.grade_3.value"
                        :color="$const.color.primary"
                        :ripple="false"
                    ></v-simple-checkbox>
                </template>
                <template #[`item.grade_4`]="{ item }">
                    <v-simple-checkbox
                        :indeterminate="!item.grade_4.show"
                        :disabled="!item.grade_4.show"
                        v-model="item.grade_4.value"
                        :color="$const.color.primary"
                        :ripple="false"
                    ></v-simple-checkbox>
                </template>
                <template #[`item.grade_5`]="{ item }">
                    <v-simple-checkbox
                        :indeterminate="!item.grade_5.show"
                        :disabled="!item.grade_5.show"
                        v-model="item.grade_5.value"
                        :color="$const.color.primary"
                        :ripple="false"
                    ></v-simple-checkbox>
                </template>
                <template #[`item.grade_6`]="{ item }">
                    <v-simple-checkbox
                        :indeterminate="!item.grade_6.show"
                        :disabled="!item.grade_6.show"
                        v-model="item.grade_6.value"
                        :color="$const.color.primary"
                        :ripple="false"
                    ></v-simple-checkbox>
                </template>
                <template #[`item.grade_7`]="{ item }">
                    <v-simple-checkbox
                        :indeterminate="!item.grade_7.show"
                        :disabled="!item.grade_7.show"
                        v-model="item.grade_7.value"
                        :color="$const.color.primary"
                        :ripple="false"
                    ></v-simple-checkbox>
                </template>
                <template #[`item.grade_8`]="{ item }">
                    <v-simple-checkbox
                        :indeterminate="!item.grade_8.show"
                        :disabled="!item.grade_8.show"
                        v-model="item.grade_8.value"
                        :color="$const.color.primary"
                        :ripple="false"
                    ></v-simple-checkbox>
                </template>
                <template #[`item.grade_9`]="{ item }">
                    <v-simple-checkbox
                        :indeterminate="!item.grade_9.show"
                        :disabled="!item.grade_9.show"
                        v-model="item.grade_9.value"
                        :color="$const.color.primary"
                        :ripple="false"
                    ></v-simple-checkbox>
                </template>
                <template #[`item.grade_10`]="{ item }">
                    <v-simple-checkbox
                        :indeterminate="!item.grade_10.show"
                        :disabled="!item.grade_10.show"
                        v-model="item.grade_10.value"
                        :color="$const.color.primary"
                        :ripple="false"
                    ></v-simple-checkbox>
                </template>
                <template #[`item.grade_11`]="{ item }">
                    <v-simple-checkbox
                        :indeterminate="!item.grade_11.show"
                        :disabled="!item.grade_11.show"
                        v-model="item.grade_11.value"
                        :color="$const.color.primary"
                        :ripple="false"
                    ></v-simple-checkbox>
                </template>
            </v-data-table>
        </div>

        <v-alert
            v-if="!_.isNil(serverErrors) && serverErrors.length"
            dense
            type="error"
            class="mt-5"
        >
            <p
                v-for="(error, index) in serverErrors"
                :key="index"
                class="mb-0"
            >{{ error }}</p>
        </v-alert>

        <v-divider class="my-4" />

        <action-buttons 
            :waiting-save-and-back="waiting.save.back"
            :waiting-save-and-update="waiting.save.update"
            @back="back"
            @save="save"
        />
    </v-form>
</template>
<script>
import { mapState } from 'vuex'
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import DataListMixin from '@/mixins/DataListMixin'
import { validationMixin } from 'vuelidate'
import retrieveOrLoadMixin from '@/mixins/retrieveOrLoadMixin'
import { required, minLength, email } from 'vuelidate/lib/validators'
import ActionButtons from '@/components/crud/ActionButtons.vue'

export default {
    components: { ActionButtons },
    props: {
        model: { type: Object }
    },
    mixins: [errorMixin, saveMixin, validationMixin, DataListMixin, retrieveOrLoadMixin],
    data() {
        return {
            storeModule: 'user',
            groups: [],
            subjects: [],
            loadingBars: {
                group: false,
                license: false
            },
            form: {
                active: true,
                role: null,
                login: '',
                new_password: '',
                confirm_password: '',
                name: '',
                gender: null,
                subjects: null,
                subjectsTable: null,
                group_id: []
            }
        };
    },
    computed: {
        ...mapState('user', ['roles']),
        roles () {
            return this.queryRole === 'admins' ?
                this.$store.state.user.roles.filter(role => ['admin_vpr', 'admin_school'].includes(role.value)) :
                this.$store.state.user.roles
        },
        isRoleStudent() {
            return this.form.role === 'student';
        },
        licenses () { return this.$store.state.license.items.map(item => item.license); },
        action() {
            return _.get(this, 'model.id', 0) <= 0 ? 'create' : 'update';
        },
        availableRoles () {
            return this.$store.state.user.roles.map(item => item.value);
        },
        queryRole () {
            return this.$route.query.role === 'undefined' ? null : this.$route.query.role;
        },
        genders () {
            return [
                { text: 'Не указано', value: null },
                { text: 'Мужской', value: 'male' },
                { text: 'Женский', value: 'female' }
            ]
        }
    },
    validations() {
        const PASSWORD_MIN_LENGTH = 6;
        return {
            form: {
                role: {
                    required
                },
                login: {
                    required: function(value) {
                        return this.isRoleStudent ? true : required(value);
                    },
                    email
                },
                new_password: {
                    required: function(value) {
                        if(this.isRoleStudent)
                            return true;
                        return this.action === 'create' ? required(value) : true;
                    },
                    minLength: minLength(PASSWORD_MIN_LENGTH)
                },
                confirm_password: {
                    required: function(value) {
                        if(this.isRoleStudent)
                            return true;
                        return this.form.new_password.length > 0 ? required(value) : true;
                    },
                    compare: function(value) {
                        if(this.isRoleStudent)
                            return true;
                        return this.form.new_password.length > 0 ? this.form.new_password === value : true;  
                    },
                    minLength: minLength(PASSWORD_MIN_LENGTH)
                },
                name: {
                    required,
                    minLength: minLength(2)
                },
                gender: {},
                subjects: {
                    required: function(value) {
                        return this.form.role === 'teacher' ? (!_.isNil(value) && _.size(value) > 0 ? true : false) : true;
                    }
                },
                group_id: {
                    required: function(value) {
                        if(this.isRoleStudent)
                            return (typeof value === 'number' && value > 0) || value?.length > 0

                        return true;
                    }
                }
            }
        }
    },
    methods: {
        setRole (role) {
            if (this.availableRoles.includes(role)) {
                this.form.role = role;
            }
        },
        prepareForm(form) {
            form = _.omit(form, ['subjectsTable']);
            form.group_id = _.isArray(form.group_id) ? form.group_id : [form.group_id];
            return form;
        },
        initSubjectsTableField() {
            const subjects = _.map(this.subjects, s => {

                let grades = {};
                _.map(_.range(1,12), n => {
                    const user_subject = _.find(this.form.subjects, {subject: s.text});
                    grades[`grade_${n}`] = {
                        grade: n,
                        value: _.includes(_.get(user_subject, 'grades', []), n) ? true : false,
                        show: _.includes(s.grades, '*') || _.includes(s.grades, n)
                    };
                });

                return {
                    subject: s.text,
                    ...grades
                }
            });
            this.$set(this.form, 'subjectsTable', subjects);
        },
        async fetchLicenses () {
            await this.retrieveOrLoad({ module: 'license', aciton: 'list' })
        }
    },
    async created () {
        if (this.queryRole !== 'admins')
            this.setRole(this.queryRole);
        
        if (this.action === 'update' && this.form.role === 'student')
            this.form.group_id = this.form.group_id[0];
        
        this.loadingBars.license = true;
        
        if (!this.licenses?.length)
            await this.fetchLicenses();
        
        this.loadingBars.license = false;
        let subjects = {};
        _.map(this.licenses, l => {
            const now = parseInt(Date.now() / 1000);
            if (!l.active || l.expires_at < now) { return; }
            _.mapKeys(_.get(l, 'code.license_type.data.subjects', null), (v,k) => {
                subjects[k] = _.compact(
                                    _.uniq(
                                        _.concat(
                                                    subjects[k],
                                                    Object.keys(v).map(grade => parseInt(grade))
                                                )
                                            )
                                        );
            });
        })

        _.mapKeys(subjects, (v,k) => {
            this.subjects.push({
                text: k,
                grades: v
            });
        });
        this.initSubjectsTableField();

        // Get groups list
        this.loadingBars.group = true;
        const rqGroups = await this.getDataList('group', { label: 'name', value: 'id' }, ['id', 'name']);
        this.groups = rqGroups;
        this.loadingBars.group = false;
    },
    watch: {
        ['form.subjectsTable']: {
            handler(val) {
                if(_.isNil(val))
                    return null;
                
                this.form.subjects = _.compact(_.map(val, o => {
                    let grades = _.compact(_.map(_.range(1,12), n => {
                        return o[`grade_${n}`].value === true ? n : null
                    }));
                    grades = _.size(grades) > 0 ? grades : null;

                    return !_.isNil(grades) ? {
                        subject: o.subject,
                        grades: grades
                    } : null;
                }));
            },
            deep: true
        }
    }
}
</script>
<style scoped lang="scss">

</style>